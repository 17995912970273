<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>

    <loading :loading="loading" />
    <v-card class="v-card-bottom-30">
      <v-card-title>
        <h4>{{ use_language.order }}</h4> <v-icon>mdi-chevron-right</v-icon><span
          class="font-weight-regular body-1 mr-2">{{ use_language.info_order }} {{ format_order_id(detail_ID) }}</span>
        <v-spacer />
        <v-btn color="primary" outlined @click="black_to_order_page()">{{ use_language.close }}</v-btn>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="v-card-bottom-30" style="background-color: transparent;">
        <v-card-text class="px-0" style="background-color: transparent;">
        <v-row v-show="this.detail_ID != ''">
          <v-col class="px-0 py-0 m-0 my-0">
            <v-card class="px-5 py-2  v-card-bottom-30" elevation="1" >
              <v-card-title>
              </v-card-title>
              <v-row>
                <v-col cols="6">
                  <h3>{{ use_language.product_list }}</h3>
                </v-col>
                
              </v-row>
              <v-row class="form-group">
                <v-col style="background: #fff ;" cols="12">
                  <v-simple-table v-if="orderItemProfit_tmp.length != 0" class="packhai-border-table">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="120px">{{ use_language.image }}</th>
                          <th class="text-left">{{ use_language.product }}</th>
                          <th class="text-left">{{ use_language.barcode }}</th>
                          <th class="text-left">{{ use_language.sku }}</th>
                          <th class="text-right">{{ use_language.quantity }}</th>
                          <th class="text-right">{{ use_language.pricesale_per_piece }}</th>
                          <th class="text-right">{{ use_language.cost_per_piece }}</th>
                          <th class="text-right">{{ use_language.discount }}</th>
                          <th class="text-right">{{ use_language.total_price }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item2 in orderItemProfit_tmp" :key="item2.id">
                          <td class="text-center">
                            <div align="center">
                              <v-img style="margin:5px;" :src="item2.photoLink" aspect-ratio="1" class="grey lighten-2"
                                width="50" height="50" @click="image_click(item2.photoLink, 0)"></v-img>
                            </div>

                          </td>
                          <td class="text-left">
                            {{ item2.productName }}
                            <span class="fn-11 color-b3">
                              {{ (item2.prop1Description != null ? item2.prop1Description : "") +
                                (item2.prop2Description
                                  != null && item2.prop2Description != '' ? ", " + item2.prop2Description : "") }}
                            </span>
                          </td>
                          <td class="text-left">{{ item2.barcode }}</td>
                          <td class="text-left">{{ item2.sku }}</td>
                          <td class="text-right">{{ item2.quantity }}</td>
                          <td class="text-right">{{ item2.unitPrice }}</td>
                          <td class="text-right">{{ item2.costUnitPrice }}</td>
                          <td class="text-right">{{ item2.discount }}</td>
                          <td class="text-right"><span v-if="item2.quantity != null">{{ format_price(item2.unitPrice *
                            item2.quantity - item2.discount) }}</span></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div v-else>-</div>
                </v-col>
              </v-row>
              <v-row>
                
                <v-col cols="12">
                  <table style="width:100%">
                    <tr v-for="(item, index) in orderProfit_tmp" :key="index">
                        <td class="text-right pb-2">
                            <strong>{{ item.description }} :</strong>
                        </td>
                        <td class="text-right pb-2" style="width: 150px;">
                            <span v-if="item.amount != null" :class="item.amount >=0 ? 'text-green' : 'text-red'">
                            {{ format_price(item.amount) }} {{ use_language.bath }}
                            </span>
                            <span v-else>0 {{ use_language.bath }}</span>
                        </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Loading from '@/website/components/Loading'
import axios from 'axios'
import {  orderService_dotnet } from '@/website/global'
import { formatDatetime, format_number, format_order_id, format_price, isNumberWNoDot,  get_languages,checkAuthorize } from '@/website/global_function'

export default {
  components: {
    Loading,
  },

  data: () => ({
    header_token: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('authorization_token') },
    shop_id: localStorage.getItem("shop_id"),
    StaffShopID: localStorage.getItem("staff_id"),
    StaffBranchID: localStorage.getItem("staff_branch_id"),
    page_loading: true,
    loading: false,
    use_language: null,
    set_language: null,
    authorize: false,
    orderProfitList: null,
    orderProfitSum: null,
    orderItemProfit_tmp: null,
    orderProfit_tmp: null,
    
    // windowSize
    size: 'lg',
    window: {
      width: 0,
      height: 0,
    },

    addOrderPopup: {
      "MaxWidth": "50%",
      "MaxHeight": 90,
      "Dialog": false,
      "headers": [
        { text: 'เลือก', align: 'center', sortable: false, value: 'selectProduct', width: '50px' },
        { text: 'รูป', align: 'center', sortable: false, value: 'photoLink', width: '60px' },
        { text: 'ชื่อ', align: 'left', sortable: false, value: 'name' },
        { text: 'SKU', align: 'center', sortable: false, value: 'sku' },
        { text: 'ราคา', align: 'right', sortable: false, value: 'unitPrice', width: '90px' },
        { text: 'คงเหลือ', align: 'right', sortable: false, value: 'quantityAvailable', width: '90px' },
      ],
      "Select_Product": [],
      "Data": [],
      "ProductID_List": [],
      "loading": false,
    },

  }),

  async created() {

    ///เก็บภาษามาไว้ใน use_language
    this.use_language = await this.get_languages(localStorage.getItem("set_language"));


    this.shop_id = localStorage.getItem("shop_id")
    //แบบเก่าแก้ไขไปบางส่วนที่ใช้แล้ว ชื่อ authorize
    this.canChangeStatusOrder = JSON.parse(localStorage.getItem('permission'))['ChangeStatusOrder']

    this.CanApprove = JSON.parse(localStorage.getItem("permission"))['ApproveOrder']

    this.header_edit_item_show = this.header_edit_item
   
    // windowSize
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
    if (this.window.width < 960) { this.addOrderPopup.MaxWidth = '100%' }
    else { this.addOrderPopup.MaxWidth = '70%' }
    if (this.size == 'xs') {
      this.limits = 5
      this.addOrderPopup.MaxHeight = (90 * this.window.height) / 100
      this.window.widthname = this.window.width - 35 - 90 - 70 - 30
    } else {
      this.addOrderPopup.MaxHeight = (this.addOrderPopup.MaxHeight * this.window.height) / 100
      // cal limits
      this.limits = parseInt((this.addOrderPopup.MaxHeight - 253) / 80)
    }
    this.detail_ID = this.$route.query.id
    if (typeof (this.detail_ID) === 'undefined') {
      this.order_detail_token = this.$route.query.token
      this.detail_ID = await this.get_orderid_by_token(this.order_detail_token)
    }

    if (this.detail_ID != null) {
      await this.ShowDetailOrder_tmp(this.detail_ID)
      document.title = "ออเดอร์ : " + this.format_order_id(this.detail_ID)

    } else {
      window.close()
    }


    this.show_reject = false
    this.page_loading = false
  },

  methods: {
    get_languages,
    checkAuthorize,
    formatDatetime,
    format_number,
    format_order_id,
    format_price,
    isNumberWNoDot,
    formatDate(date) {
      date = [date]
      if (date.length == 0) return null

      if (date.length == 1) {
        const [year, month, day] = date[0].split('-')
        return `${day}-${month}-${year}`
      } else {
        const [year_from, month_from, day_from] = date[0].split('-')
        const [year_to, month_to, day_to] = date[1].split('-')
        if (date[0] < date[1]) {
          return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
        } else {
          return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
        }
      }
    },

    // windowSize
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      if (this.window.width < 600) {
        this.size = 'xs'
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = 'sm'
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = 'md'
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = 'lg'
      } else if (this.window.width >= 1904) {
        this.size = 'xl'
      }
    },
    black_to_order_page() {
      window.close()
    },

    async ShowDetailOrder_tmp(OrderID) {
      await axios.all([
        axios.get(orderService_dotnet + 'Order/get-profit-report-detail?OrderMasterID=' + OrderID, { headers: this.header_token }),
      ]).then(axios.spread((res_OrderProfit) => {

        this.orderProfitList= res_OrderProfit.data.orderItems;
        this.orderProfitSum = res_OrderProfit.data.detailTable;
        this.orderItemProfit_tmp= this.orderProfitList;
        this.orderProfit_tmp = this.orderProfitSum;
      }));
    },
  }
}
</script>
<style scoped>
/* >>>.v-dialog {
      -webkit-box-shadow: none;
      box-shadow: none;
  } */
.data-table-select-column {
  display: inline-block;
  width: 2px;
  text-align: center;

}

>>>.bex48-logo {
  width: 12.533vw;
  height: 11.2vw;
  display: block;
  /* margin: 8.8vw auto 1.6vw; */
  margin: 1.6vw auto 1.6vw;
}

>>>.slide-fade-enter-active {
  transition: all .3s ease;
}

>>>.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

>>>.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateY(10px);
  opacity: 0;
}

.packhai-border-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.packhai-border-table thead {
  color: white;
}

.packhai-border-table thead tr th {

  font-weight: bold;
  font-size: 1rem;
}

.return-order-table {
  width: 100%;
}

.padding-for-order-dialog {
  padding: 0px !important;
}

@media screen and (min-width: 768px) {
  .return-order-table {
    width: 300px;
  }

  .padding-for-order-dialog {
    padding: 15px !important;
  }
}

.packhai-border-preview {
  border-top: solid 1px black;
  border-left: solid 1px black;
  border-right: solid 1px black;
  border-bottom: solid 1px black;
  border-radius: 10px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-14 {
  font-size: 14px !important;
}
.text-green {
    color: green;
    font-weight: bold;
}
.text-red {
    color: red;
    font-weight: bold;
}
</style>
